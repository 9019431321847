import React, { useState, useEffect } from 'react';
import AppStore from '../ButtonsAppStore/AppStore';
import ScreenShotsGallery from '../ScreenShotsGallery/ScreenShotsGallery';
import Info from '../Info/Info';

function MainPage() {

  const [isShowGallery, setIsShowGallery] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowGallery(true);
    }, 1500)
  }, [])

  return (
    <section className='mainPage'>
      <h1 className='main-page__title'>Авто Дневник</h1>
      <h2 className='main-page__subtitle'>Мобильное приложение для учета обслуживания автомобиля или другого транспорта</h2>
      <AppStore />
      {isShowGallery ?
        <ScreenShotsGallery isAnimated={isShowGallery} />
        : <></>}
      {isShowGallery ?
        <Info isAnimated={isShowGallery} />
        : <></>}
    </section>
  )
}

export default MainPage;