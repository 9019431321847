import React, { useState, useEffect } from 'react';

import imgAuto from './../../Images/withoutweels.png';
import imgWeel from './../../Images/wheel1.png';

function AutoAnimated({ isRotating, isTrailer, setLoadedImg }) {

    const [isLoadedAuto, setIsLoadedAuto] = useState(false);
    const [isLoadedWeel, setIsLoadedWeel] = useState(false);

    useEffect(() => {

        if (isLoadedAuto && isLoadedWeel) {
            setLoadedImg(true);
        }

    }, [isLoadedAuto, isLoadedWeel])

    return (
        <div className='auto-animated'>
            <img src={imgAuto} alt='auto' className='auto-animated__auto' onLoad={() => setIsLoadedAuto(true)} />
            <img src={imgWeel} alt='auto' className={`auto-animated__weel1 ${isRotating ? 'rotating' : ''}`} onLoad={() => setIsLoadedWeel(true)} />
            <img src={imgWeel} alt='auto' className={`auto-animated__weel2 ${isRotating ? 'rotating' : ''}`} onLoad={() => setIsLoadedWeel(true)} />
            {
                isTrailer ?
                    <div className={`auto-animated__line ${isRotating ? '' : ''}`} />
                    : <></>
            }
        </div >
    )
}

export default AutoAnimated;