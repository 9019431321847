import React, { useEffect, useState } from 'react';

import img1 from '../../Images/screenshots/Screenshotw1.png';
import img2 from '../../Images/screenshots/Screenshotw2.png';
import img3 from '../../Images/screenshots/Screenshotb1.png';
import img4 from '../../Images/screenshots/Screenshotb2.png';


function ScreenShotsGallery({ }) {
    const [isAnimated, setIsAnimated] = useState(false);
    const [isLoadedw1, setIsLoadedw1] = useState(false);
    const [isLoadedw2, setIsLoadedw2] = useState(false);
    const [isLoadedb1, setIsLoadedb1] = useState(false);
    const [isLoadedb2, setIsLoadedb2] = useState(false);

    useEffect(() => {
        const arrayLoaded = [isLoadedw1, isLoadedw2, isLoadedb1, isLoadedb2];
        if (!arrayLoaded.includes(false)) {
            setTimeout(() => { setIsAnimated(true); }, 300)
        }
    }, [isLoadedw1, isLoadedw2, isLoadedb1, isLoadedb2])


    const styleW1 = `screen-shots-gallery__img ${isAnimated ? 'screen-shots-gallery__w1' : ''}`
    const styleW2 = `screen-shots-gallery__img ${isAnimated ? 'screen-shots-gallery__w2' : ''}`
    const styleB1 = `screen-shots-gallery__img ${isAnimated ? 'screen-shots-gallery__b1' : ''}`
    const styleB2 = `screen-shots-gallery__img ${isAnimated ? 'screen-shots-gallery__b2' : ''}`


    return (
        <div className='screen-shots-gallery'>
            <img src={img1} alt='screen-shot' className={styleW1} onLoad={() => { setIsLoadedw1(true) }} />
            <img src={img2} alt='screen-shot' className={styleW2} onLoad={() => { setIsLoadedw2(true) }} />
            <img src={img3} alt='screen-shot' className={styleB1} onLoad={() => { setIsLoadedb1(true) }} />
            <img src={img4} alt='screen-shot' className={styleB2} onLoad={() => { setIsLoadedb2(true) }} />
        </div>
    )
}

export default ScreenShotsGallery;