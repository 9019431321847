import React, { useEffect, useState } from 'react';
import ButtonAppStore from '../ButtonAppStore/ButtonAppStore';
import imgApple from '../../Images/Apple.svg';
import imgGoogle from '../../Images/google-play-icon-svgrepo-com.svg';
import AutoAnimated from '../AutoAnimated/AutoAnimated';

function AppStore() {
    const [isAnimated, setIsAnimated] = useState(false);
    const [isAnimated2, setIsAnimated2] = useState(false);
    const [isLoadedImg, setLoadedImg] = useState(false);

    useEffect(() => {
        // Задержка добавления класса для плавного появления
        if (isLoadedImg) {
            const timeoutId = setTimeout(() => {
                setIsAnimated(true);
            }, 100);

            setTimeout(() => {
                setIsAnimated2(true);
            }, 1100);

            return () => clearTimeout(timeoutId);
        }
    }, [isLoadedImg]);

    function openAppStore() {
        const appStoreUrl = 'https://apps.apple.com/us/app/auto-diary/id6471256611';
        window.open(appStoreUrl, '_blank');
    }

    function openGooglePlay() {
        const googlePlayUrl = 'https://play.google.com/store/apps/details?id=com.slavarichkov.dnevnikAutoApp';
        window.open(googlePlayUrl, '_blank');
    }

    const appStoreClass = `app-store ${isAnimated ? 'app-store_animated' : ''}`;
    const autoAnimatedClass = `app-store__auto ${isAnimated2 ? 'app-store__auto_animated' : ''}`;
    const buttonsAnimatedClass = `app-store__container-buttons-text ${isAnimated2 ? 'app-store__container-buttons-text_animated' : ''}`;

    return (
        <div className={appStoreClass}>
            < div className={autoAnimatedClass} >
                <AutoAnimated isRotating={isAnimated} isTrailer={!isAnimated2} setLoadedImg={setLoadedImg} />
            </div >
            <div className={buttonsAnimatedClass}>
                <p className='app-store__title'>Скачать</p>
                <div className="app-store__container-buttons">
                    <ButtonAppStore img={imgApple} text={'AppStore'} onClick={openAppStore} />
                    <ButtonAppStore img={imgGoogle} text={'GooglePlay'} onClick={openGooglePlay} />
                </div>
            </div>
        </div >
    )
}

export default AppStore;