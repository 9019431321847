

function MeterReadings() {
    
    return (

        <div className="privacy-page">
                    <h2 className="privacy-page_title">Передача счетчиков</h2>
                    <h3 className="privacy-page_subtitle">Почта для связи: meter-readings@lava-app.ru</h3>
        </div>
    )
}

export default MeterReadings;