import React, { useEffect, useState } from 'react';

function Info() {
    const [animated, setAnimated] = useState(false);

    useEffect(() => {
        setTimeout(() => { setAnimated(true); }, 1000)
    }, []);


    return (
            <div className={`info ${animated ? 'info_animated' : ''}`}>
                <p className="info__element"><p className="info__text">Ведение учета обслуживания автомобиля или другого транспорта: Записывайте все работы, техническое обслуживание (ТО), сервисное обслуживание,  ремонты и замены деталей. Следите за пробегом, заменой масла и многими другими параметрами.</p></p>
                <p className="info__element"><p className="info__text">Обмен историей обслуживания: Делитесь информацией о вашем транспорте с другими пользователями. Это особенно полезно при продаже или покупке транспортного средства. Покупатели смогут легко оценить состояние автомобиля</p></p>
                <p className="info__element"><p className="info__text">Подробные статистические данные: Получайте информацию о затратах на обслуживание,  других важных параметрах вашего автомобиля.</p></p>
                <p className="info__element"><p className="info__text"> Надежное хранение данных: Ваши данные хранятся в безопасности. Мы ценим вашу конфиденциальность.</p></p>
            </div>
    )
}

export default Info;