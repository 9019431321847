function PrivacyPagePt() {
    return (
        <div className="privacy-page">
            <h1>Política de Privacidade</h1>
            <p>Última atualização: 10 de novembro de 2023</p>
            <p>Esta Política de Privacidade descreve nossas políticas e procedimentos sobre a coleta, uso e divulgação de suas informações quando você utiliza o Serviço e informa sobre seus direitos de privacidade e como a lei o protege.</p>
            <p>Utilizamos seus dados pessoais para fornecer e melhorar o Serviço. Ao utilizar o Serviço, você concorda com a coleta e uso de informações de acordo com esta Política de Privacidade. Esta Política de Privacidade foi criada com a ajuda do <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Gerador de Política de Privacidade</a>.</p>
            <h2>Interpretação e Definições</h2>
            <h3>Interpretação</h3>
            <p>As palavras cuja inicial está em maiúscula têm significados definidos nas seguintes condições. As seguintes definições têm o mesmo significado, independentemente de aparecerem no singular ou no plural.</p>
            <h3>Definições</h3>
            <p>Para os fins desta Política de Privacidade:</p>
            <ul>
                <li>
                    <p><strong>Conta</strong> significa uma conta única criada para você acessar nosso Serviço ou partes do nosso Serviço.</p>
                </li>
                <li>
                    <p><strong>Afiliado</strong> significa uma entidade que controla, é controlada por ou está sob controle comum com uma parte, onde "controle" significa a propriedade de 50% ou mais das ações, participação acionária ou outros títulos com direito a voto para a eleição de diretores ou outra autoridade de gestão.</p>
                </li>
                <li>
                    <p><strong>Aplicativo</strong> refere-se ao Auto-Diary, o programa de software fornecido pela Empresa.</p>
                </li>
                <li>
                    <p><strong>Empresa</strong> (referida como "a Empresa", "Nós", "Nosso" ou "Nossos" neste Acordo) refere-se ao Auto-Diary.</p>
                </li>
                <li>
                    <p><strong>País</strong> refere-se a: Rússia</p>
                </li>
                <li>
                    <p><strong>Dispositivo</strong> significa qualquer dispositivo que pode acessar o Serviço, como um computador, um celular ou um tablet digital.</p>
                </li>
                <li>
                    <p><strong>Dados Pessoais</strong> são todas as informações que se relacionam a uma pessoa identificada ou identificável.</p>
                </li>
                <li>
                    <p><strong>Serviço</strong> refere-se ao Aplicativo.</p>
                </li>
                <li>
                    <p><strong>Prestador de Serviço</strong> significa qualquer pessoa natural ou jurídica que processa os dados em nome da Empresa. Refere-se a empresas ou indivíduos de terceiros contratados pela Empresa para facilitar o Serviço, fornecer o Serviço em nome da Empresa, realizar serviços relacionados ao Serviço ou ajudar a Empresa na análise de como o Serviço é utilizado.</p>
                </li>
                <li>
                    <p><strong>Dados de Uso</strong> refere-se a dados coletados automaticamente, gerados pelo uso do Serviço ou pela infraestrutura do Serviço em si (por exemplo, a duração de uma visita à página).</p>
                </li>
                <li>
                    <p><strong>Você</strong> significa a pessoa física que acessa ou utiliza o Serviço, ou a empresa ou outra entidade legal em nome da qual essa pessoa física está acessando ou utilizando o Serviço, conforme aplicável.</p>
                </li>
            </ul>
            <h2>Coleta e Uso de Seus Dados Pessoais</h2>
            <h3>Tipos de Dados Coletados</h3>
            <h4>Dados Pessoais</h4>
            <p>Ao utilizar nosso Serviço, podemos solicitar que você nos forneça determinadas informações pessoais identificáveis que podem ser usadas para contatá-lo ou identificá-lo. Informações pessoais identificáveis podem incluir, mas não estão limitadas a:</p>
            <ul>
                <li>
                    <p>Endereço de e-mail</p>
                </li>
                <li>
                    <p>Dados de Uso</p>
                </li>
            </ul>
            <h4>Dados de Uso</h4>
            <p>Dados de Uso são coletados automaticamente ao usar o Serviço.</p>
            <p>Dados de Uso podem incluir informações como o endereço de Protocolo de Internet (por exemplo, endereço IP) do seu Dispositivo, tipo de navegador, versão do navegador, as páginas do nosso Serviço que você visita, o horário e a data de sua visita, o tempo gasto nessas páginas, identificadores exclusivos do dispositivo e outros dados diagnósticos.</p>
            <p>Quando você acessa o Serviço por meio de um dispositivo móvel, podemos coletar automaticamente determinadas informações, incluindo, mas não se limitando ao tipo de dispositivo móvel que você usa, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, seu sistema operacional móvel, o tipo de navegador de Internet móvel que você usa, identificadores exclusivos do dispositivo e outros dados diagnósticos.</p>
            <p>Também podemos coletar informações que seu navegador envia sempre que você visita nosso Serviço ou quando você acessa o Serviço por meio de um dispositivo móvel.</p>
            <h4>Informações Coletadas ao Usar o Aplicativo</h4>
            <p>Ao usar nosso Aplicativo, para fornecer recursos do nosso Aplicativo, podemos coletar, com sua permissão prévia:</p>
            <ul>
                <li>
                    <p>Informações sobre sua localização</p>
                </li>
                <li>
                    <p>Fotos e outras informações da câmera e biblioteca de fotos do seu Dispositivo</p>
                </li>
            </ul>
            <p>Utilizamos essas informações para fornecer recursos do nosso Serviço, para melhorar e personalizar nosso Serviço. As informações podem ser enviadas para os servidores da Empresa e/ou de um Prestador de Serviço, ou podem ser simplesmente armazenadas em seu dispositivo.</p>
            <p>Você pode ativar ou desativar o acesso a essas informações a qualquer momento, por meio das configurações do seu Dispositivo.</p>
            <h3>Uso de Seus Dados Pessoais</h3>
            <p>A Empresa pode utilizar Dados Pessoais para os seguintes fins:</p>
            <ul>
                <li>
                    <p><strong>Para fornecer e manter nosso Serviço</strong>, incluindo monitorar o uso do nosso Serviço.</p>
                </li>
                <li>
                    <p><strong>Para gerenciar sua Conta:</strong> gerenciar seu registro como usuário do Serviço. Os Dados Pessoais que você fornece podem conceder acesso a diferentes funcionalidades do Serviço que estão disponíveis para você como usuário registrado.</p>
                </li>
                <li>
                    <p><strong>Para o desempenho de um contrato:</strong> o desenvolvimento, cumprimento e realização do contrato de compra dos produtos, itens ou serviços que você adquiriu ou de qualquer outro contrato conosco por meio do Serviço.</p>
                </li>
                <li>
                    <p><strong>Para entrar em contato com você:</strong> entrar em contato com você por e-mail, chamadas telefônicas, SMS ou outras formas equivalentes de comunicação eletrônica, como notificações push de aplicativos móveis, sobre atualizações ou comunicações informativas relacionadas às funcionalidades, produtos ou serviços contratados, incluindo atualizações de segurança, quando necessário ou razoável para sua implementação.</p>
                </li>
                <li>
                    <p><strong>Para fornecer a você</strong> notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos e que são semelhantes aos que você já adquiriu ou perguntou, a menos que você tenha optado por não receber tais informações.</p>
                </li>
                <li>
                    <p><strong>Para gerenciar suas solicitações:</strong> atender e gerenciar suas solicitações para nós.</p>
                </li>
                <li>
                    <p><strong>Para transferências comerciais:</strong> podemos usar suas informações para avaliar ou realizar uma fusão, cisão, reestruturação, reorganização, dissolução ou outra venda ou transferência de parte ou todos os nossos ativos, seja como empresa em funcionamento ou como parte de falência, liquidação ou procedimento semelhante, no qual Dados Pessoais detidos por nós sobre os usuários do nosso Serviço estão entre os ativos transferidos.</p>
                </li>
                <li>
                    <p><strong>Para outros fins</strong>: podemos usar suas informações para outros fins, como análise de dados, identificação de tendências de uso, determinação da eficácia de nossas campanhas promocionais e para avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua experiência.</p>
                </li>
            </ul>
            <h3>Compartilhamento de Suas Informações Pessoais</h3>
            <p>Nós podemos compartilhar Suas informações pessoais nas seguintes situações:</p>
            <ul>
                <li><strong>Com Prestadores de Serviço:</strong> Podemos compartilhar Suas informações pessoais com Prestadores de Serviço para monitorar e analisar o uso do nosso Serviço, para entrar em contato com Você.</li>
                <li><strong>Em transferências comerciais:</strong> Podemos compartilhar ou transferir Suas informações pessoais em conexão com, ou durante negociações de, qualquer fusão, venda de ativos da Empresa, financiamento ou aquisição de todo ou parte do Nosso negócio por outra empresa.</li>
                <li><strong>Com Afiliados:</strong> Podemos compartilhar Suas informações com Nossos afiliados, nesse caso, solicitaremos que esses afiliados respeitem esta Política de Privacidade. Afiliados incluem Nossa empresa-mãe e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que controlamos ou que estão sob controle comum conosco.</li>
                <li><strong>Com parceiros comerciais:</strong> Podemos compartilhar Suas informações com Nossos parceiros comerciais para oferecer a Você determinados produtos, serviços ou promoções.</li>
                <li><strong>Com outros usuários:</strong> Quando Você compartilha informações pessoais ou interage em áreas públicas com outros usuários, tais informações podem ser visualizadas por todos os usuários e podem ser distribuídas publicamente.</li>
                <li><strong>Com Seu consentimento:</strong> Podemos divulgar Suas informações pessoais para qualquer outro fim com Seu consentimento.</li>
            </ul>
            <h3>Retenção de Seus Dados Pessoais</h3>
            <p>A Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário para os fins estabelecidos nesta Política de Privacidade. Retornaremos e utilizaremos Seus Dados Pessoais na medida do necessário para cumprir com nossas obrigações legais (por exemplo, se formos obrigados a reter seus dados para cumprir com leis aplicáveis), resolver disputas e fazer valer nossos acordos legais e políticas.</p>
            <p>A Empresa também reterá Dados de Uso para fins de análise interna. Dados de Uso geralmente são retidos por um período mais curto, exceto quando esses dados são usados para fortalecer a segurança ou melhorar a funcionalidade do Nosso Serviço, ou quando somos legalmente obrigados a reter esses dados por períodos mais longos.</p>
            <h3>Transferência de Seus Dados Pessoais</h3>
            <p>Suas informações, incluindo Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em qualquer outro local onde as partes envolvidas no processamento estão localizadas. Isso significa que essas informações podem ser transferidas para — e mantidas em — computadores localizados fora de Seu estado, província, país ou outra jurisdição governamental, onde as leis de proteção de dados podem ser diferentes das leis de Sua jurisdição.</p>
            <p>Seu consentimento a esta Política de Privacidade, seguido por Sua submissão dessas informações, representa Sua concordância com essa transferência.</p>
            <p>A Empresa tomará todas as medidas razoavelmente necessárias para garantir que Seus dados sejam tratados de forma segura e de acordo com esta Política de Privacidade, e nenhuma transferência de Seus Dados Pessoais será realizada para uma organização ou país, a menos que haja controles adequados em vigor, incluindo a segurança de Seus dados e outras informações pessoais.</p>
            <h3>Excluir Seus Dados Pessoais</h3>
            <p>Você tem o direito de excluir ou solicitar que Nós ajudemos a excluir os Dados Pessoais que coletamos sobre Você.</p>
            <p>Nosso Serviço pode oferecer a Você a capacidade de excluir certas informações sobre Você de dentro do Serviço.</p>
            <p>Você pode atualizar, corrigir ou excluir Suas informações a qualquer momento ao fazer login em Sua Conta, se tiver uma, e visitar a seção de configurações da conta que permite gerenciar Suas informações pessoais. Você também pode entrar em contato conosco para solicitar acesso, correção ou exclusão de qualquer informação pessoal que Você tenha fornecido para Nós.</p>
            <p>Observe, no entanto, que podemos precisar reter certas informações quando tivermos uma obrigação legal ou base legal para fazê-lo.</p>
            <h3>Divulgação de Seus Dados Pessoais</h3>
            <h4>Transações Comerciais</h4>
            <p>Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, Seus Dados Pessoais podem ser transferidos. Forneceremos aviso antes que Seus Dados Pessoais sejam transferidos e se tornem sujeitos a uma Política de Privacidade diferente.</p>
            <h4>Aplicação da Lei</h4>
            <p>Em certas circunstâncias, a Empresa pode ser obrigada a divulgar Seus Dados Pessoais se exigido por lei ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou uma agência governamental).</p>
            <h4>Outros requisitos legais</h4>
            <p>A Empresa pode divulgar Seus Dados Pessoais na boa fé de que tal ação é necessária para:</p>
            <ul>
                <li>Cumprir uma obrigação legal</li>
                <li>Proteger e defender os direitos ou propriedade da Empresa</li>
                <li>Prevenir ou investigar possíveis irregularidades relacionadas ao Serviço</li>
                <li>Proteger a segurança pessoal dos usuários do Serviço ou do público</li>
                <li>Proteger contra responsabilidade legal</li>
            </ul>
            <h3>Segurança de Seus Dados Pessoais</h3>
            <p>A segurança de Seus Dados Pessoais é importante para Nós, mas lembre-se de que nenhum método de transmissão pela Internet, ou método de armazenamento eletrônico, é 100% seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis para proteger Seus Dados Pessoais, não podemos garantir sua segurança absoluta.</p>
            <h2>Privacidade de Crianças</h2>
            <p>Nosso Serviço não se destina a pessoas com menos de 13 anos. Não coletamos conscientemente informações de identificação pessoal de ninguém com menos de 13 anos. Se Você for pai ou responsável e estiver ciente de que Seu filho nos forneceu Dados Pessoais, entre em contato conosco. Se ficarmos cientes de que coletamos Dados Pessoais de qualquer pessoa com menos de 13 anos sem verificação de consentimento dos pais, tomamos medidas para remover essas informações de nossos servidores.</p>
            <p>Se precisarmos depender do consentimento como base legal para o processamento de Suas informações e Seu país exigir consentimento de um pai, poderemos solicitar o consentimento de Seus pais antes de coletar e usar essas informações.</p>
            <h2>Links para Outros Sites</h2>
            <p>Nosso Serviço pode conter links para outros sites que não são operados por Nós. Se Você clicar em um link de terceiros, será direcionado para o site desse terceiro. Recomendamos fortemente que Você revise a Política de Privacidade de cada site que visitar.</p>
            <p>Não temos controle sobre e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou práticas de qualquer site ou serviço de terceiros.</p>
            <h2>Alterações a esta Política de Privacidade</h2>
            <p>Podemos atualizar Nossa Política de Privacidade de tempos em tempos. Notificaremos Você sobre quaisquer alterações postando a nova Política de Privacidade nesta página.</p>
            <p>Informaremos Você por e-mail e/ou por aviso proeminente em Nosso Serviço, antes que a alteração entre em vigor, e atualizaremos a data "Última Atualização" no topo desta Política de Privacidade.</p>
            <p>Recomendamos que Você revise esta Política de Privacidade periodicamente para quaisquer alterações. Alterações a esta Política de Privacidade são eficazes quando são postadas nesta página.</p>
            <h2>Entre em Contato Conosco</h2>
            <p>Se você tiver alguma dúvida sobre esta Política de Privacidade, pode entrar em contato conosco:</p>
            <ul>
                <li>Por e-mail: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="066f68606946677372692b62686370686f6d287473">[email&nbsp;protected]</a></li>
            </ul>

        </div>
    )
}

export default PrivacyPagePt;