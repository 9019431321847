function PrivacyPageIn() {

    return (
        <div className="privacy-page">
            <h1>Kebijakan Privasi</h1>
            <p>Terakhir diperbarui: 10 November 2023</p>
            <p>Kebijakan Privasi ini menjelaskan kebijakan dan prosedur Kami tentang pengumpulan, penggunaan, dan pengungkapan informasi Anda saat Anda menggunakan Layanan dan memberi tahu Anda tentang hak privasi Anda dan bagaimana hukum melindungi Anda.</p>
            <p>Kami menggunakan data pribadi Anda untuk menyediakan dan meningkatkan Layanan. Dengan menggunakan Layanan, Anda setuju untuk pengumpulan dan penggunaan informasi sesuai dengan Kebijakan Privasi ini. Kebijakan Privasi ini telah dibuat dengan bantuan <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Pembuat Kebijakan Privasi</a>.</p>
            <h2>Interpretasi dan Definisi</h2>
            <h3>Interpretasi</h3>
            <p>Kata-kata yang huruf pertamanya dikapitalisasi memiliki arti yang ditentukan dalam kondisi berikut. Definisi berikut memiliki arti yang sama tanpa memandang apakah mereka muncul dalam bentuk tunggal atau jamak.</p>
            <h3>Definisi</h3>
            <p>Untuk keperluan Kebijakan Privasi ini:</p>
            <ul>
                <li>
                    <p><strong>Akun</strong> berarti akun unik yang dibuat untuk Anda untuk mengakses Layanan kami atau bagian dari Layanan kami.</p>
                </li>
                <li>
                    <p><strong>Afiliasi</strong> berarti entitas yang mengendalikan, dikendalikan oleh, atau berada di bawah kendali bersama dengan suatu pihak, di mana "kendali" berarti kepemilikan 50% atau lebih dari saham, kepentingan ekuitas, atau sekuritas lain yang berhak memberikan suara untuk pemilihan direktur atau otoritas pengelola lainnya.</p>
                </li>
                <li>
                    <p><strong>Aplikasi</strong> merujuk pada Auto-Diary, program perangkat lunak yang disediakan oleh Perusahaan.</p>
                </li>
                <li>
                    <p><strong>Perusahaan</strong> (disebut sebagai "Perusahaan", "Kami", "Kami", atau "Kami" dalam Perjanjian ini) mengacu pada Auto-Diary.</p>
                </li>
                <li>
                    <p><strong>Negara</strong> merujuk pada: Rusia</p>
                </li>
                <li>
                    <p><strong>Perangkat</strong> berarti setiap perangkat yang dapat mengakses Layanan seperti komputer, ponsel, atau tablet digital.</p>
                </li>
                <li>
                    <p><strong>Data Pribadi</strong> adalah informasi apa pun yang berkaitan dengan individu yang diidentifikasi atau dapat diidentifikasi.</p>
                </li>
                <li>
                    <p><strong>Layanan</strong> merujuk pada Aplikasi.</p>
                </li>
                <li>
                    <p><strong>Penyedia Layanan</strong> berarti setiap orang atau badan hukum yang memproses data atas nama Perusahaan. Ini merujuk pada perusahaan atau individu pihak ketiga yang dipekerjakan oleh Perusahaan untuk memfasilitasi Layanan, menyediakan Layanan atas nama Perusahaan, melakukan layanan terkait dengan Layanan, atau membantu Perusahaan dalam menganalisis bagaimana Layanan digunakan.</p>
                </li>
                <li>
                    <p><strong>Data Penggunaan</strong> merujuk pada data yang dikumpulkan secara otomatis, baik yang dihasilkan oleh penggunaan Layanan atau dari infrastruktur Layanan itu sendiri (misalnya, durasi kunjungan halaman).</p>
                </li>
                <li>
                    <p><strong>Anda</strong> berarti individu yang mengakses atau menggunakan Layanan, atau perusahaan, atau entitas hukum lain atas nama individu tersebut yang mengakses atau menggunakan Layanan, sesuai dengan yang berlaku.</p>
                </li>
            </ul>
            <h2>Mengumpulkan dan Menggunakan Data Pribadi Anda</h2>
            <h3>Jenis Data yang Dikumpulkan</h3>
            <h4>Data Pribadi</h4>
            <p>Saat menggunakan Layanan Kami, Kami dapat meminta Anda untuk memberikan Kami beberapa informasi yang dapat diidentifikasi secara pribadi yang dapat digunakan untuk menghubungi atau mengidentifikasi Anda. Informasi yang dapat diidentifikasi secara pribadi dapat mencakup, tetapi tidak terbatas pada:</p>
            <ul>
                <li>
                    <p>Alamat email</p>
                </li>
                <li>
                    <p>Data Penggunaan</p>
                </li>
            </ul>
            <h4>Data Penggunaan</h4>
            <p>Data Penggunaan dikumpulkan secara otomatis saat menggunakan Layanan.</p>
            <p>Data Penggunaan dapat mencakup informasi seperti alamat Protokol Internet Perangkat Anda (misalnya, alamat IP), jenis browser, versi browser, halaman Layanan kami yang Anda kunjungi, waktu dan tanggal kunjungan Anda, waktu yang dihabiskan di halaman-halaman tersebut, pengidentifikasi perangkat unik, dan data diagnostik lainnya.</p>
            <p>Ketika Anda mengakses Layanan melalui perangkat seluler, Kami dapat mengumpulkan informasi tertentu secara otomatis, termasuk, tetapi tidak terbatas pada, jenis perangkat seluler yang Anda gunakan, ID unik perangkat seluler Anda, alamat IP perangkat seluler Anda, sistem operasi seluler Anda, jenis browser internet seluler yang Anda gunakan, pengidentifikasi perangkat unik, dan data diagnostik lainnya.</p>
            <p>Kami juga dapat mengumpulkan informasi yang dikirimkan oleh browser Anda setiap kali Anda mengunjungi Layanan kami atau saat Anda mengakses Layanan melalui perangkat seluler.</p>
            <h4>Informasi yang Dikumpulkan Saat Menggunakan Aplikasi</h4>
            <p>Saat menggunakan Aplikasi Kami, untuk menyediakan fitur-fitur Aplikasi Kami, Kami dapat mengumpulkan, dengan izin Anda sebelumnya:</p>
            <ul>
                <li>
                    <p>Informasi tentang lokasi Anda</p>
                </li>
                <li>
                    <p>Gambar dan informasi lain dari kamera dan perpustakaan foto perangkat Anda</p>
                </li>
            </ul>
            <p>Kami menggunakan informasi ini untuk menyediakan fitur Layanan Kami, untuk meningkatkan dan menyesuaikan Layanan Kami. Informasi tersebut dapat diunggah ke server Perusahaan dan/atau server Penyedia Layanan atau mungkin hanya disimpan di perangkat Anda.</p>
            <p>Anda dapat mengaktifkan atau menonaktifkan akses ke informasi ini kapan saja melalui pengaturan Perangkat Anda.</p>
            <h3>Penggunaan Data Pribadi Anda</h3>
            <p>Perusahaan dapat menggunakan Data Pribadi untuk tujuan-tujuan berikut:</p>
            <ul>
                <li>
                    <p><strong>Untuk menyediakan dan memelihara Layanan kami</strong>, termasuk untuk memonitor penggunaan Layanan kami.</p>
                </li>
                <li>
                    <p><strong>Untuk mengelola Akun Anda:</strong> untuk mengelola registrasi Anda sebagai pengguna Layanan. Data Pribadi yang Anda berikan dapat memberikan akses kepada Anda untuk berbagai fungsionalitas Layanan yang tersedia untuk Anda sebagai pengguna terdaftar.</p>
                </li>
                <li>
                    <p><strong>Untuk pelaksanaan kontrak:</strong> pengembangan, kepatuhan, dan pelaksanaan kontrak pembelian produk, barang, atau layanan yang telah Anda beli atau kontrak lain dengan Kami melalui Layanan.</p>
                </li>
                <li>
                    <p><strong>Untuk menghubungi Anda:</strong> Untuk menghubungi Anda melalui email, panggilan telepon, SMS, atau bentuk komunikasi elektronik lainnya, seperti pemberitahuan push aplikasi seluler mengenai pembaruan atau komunikasi informatif terkait fungsionalitas, produk, atau layanan yang dikontrak, termasuk pembaruan keamanan, jika diperlukan atau wajar untuk pelaksanaannya.</p>
                </li>
                <li>
                    <p><strong>Untuk menyediakan Anda</strong> dengan berita, penawaran khusus, dan informasi umum tentang barang, layanan, dan acara lain yang kami tawarkan yang mirip dengan yang sudah Anda beli atau tanyakan kecuali jika Anda memilih untuk tidak menerima informasi tersebut.</p>
                </li>
                <li>
                    <p><strong>Untuk mengelola permintaan Anda:</strong> Untuk menghadiri dan mengelola permintaan Anda kepada Kami.</p>
                </li>
                <li>
                    <p><strong>Untuk transfer bisnis:</strong> Kami dapat menggunakan informasi Anda untuk mengevaluasi atau melakukan merger, pelepasan, restrukturisasi, reorganisasi, likuidasi, atau penjualan atau transfer lain dari sebagian atau seluruh aset Kami, baik sebagai usaha berjalan atau sebagai bagian dari kebangkrutan, likuidasi, atau proses serupa, di mana Data Pribadi yang Kami miliki tentang pengguna Layanan Kami termasuk dalam aset yang ditransfer.</p>
                </li>
                <li>
                    <p><strong>Untuk tujuan lain</strong>: Kami dapat menggunakan informasi Anda untuk tujuan lain, seperti analisis data, mengidentifikasi tren penggunaan, menentukan efektivitas kampanye promosi kami, dan untuk mengevaluasi dan meningkatkan Layanan kami, produk, layanan, pemasaran, dan pengalaman Anda.</p>
                </li>
            </ul>
            <p>Kami dapat membagikan informasi pribadi Anda dalam situasi berikut:</p>
            <ul>
                <li><strong>Dengan Penyedia Layanan:</strong> Kami dapat membagikan informasi pribadi Anda dengan Penyedia Layanan untuk memantau dan menganalisis penggunaan Layanan kami, untuk menghubungi Anda.</li>
                <li><strong>Untuk transfer bisnis:</strong> Kami dapat membagikan atau mentransfer informasi pribadi Anda sehubungan dengan, atau selama negosiasi, setiap penggabungan, penjualan aset Perusahaan, pendanaan, atau akuisisi seluruhnya atau sebagian dari bisnis Kami ke perusahaan lain.</li>
                <li><strong>Dengan Afiliasi:</strong> Kami dapat membagikan informasi Anda dengan Afiliasi Kami, dalam hal ini, kami akan menuntut afiliasi tersebut untuk mematuhi Kebijakan Privasi ini. Afiliasi termasuk Perusahaan induk Kami dan anak perusahaan, mitra joint venture, atau perusahaan lain yang Kami kendalikan atau yang berada di bawah kendali bersama dengan Kami.</li>
                <li><strong>Dengan mitra bisnis:</strong> Kami dapat membagikan informasi Anda dengan mitra bisnis Kami untuk menawarkan Anda produk, layanan, atau promosi tertentu.</li>
                <li><strong>Dengan pengguna lain:</strong> Ketika Anda berbagi informasi pribadi atau berinteraksi di area publik dengan pengguna lain, informasi tersebut dapat dilihat oleh semua pengguna dan mungkin disebarluaskan secara publik.</li>
                <li><strong>Dengan persetujuan Anda</strong>: Kami dapat mengungkapkan informasi pribadi Anda untuk tujuan lain dengan persetujuan Anda.</li>
            </ul>
            <h3>Penyimpanan Data Pribadi Anda</h3>
            <p>Perusahaan akan menyimpan Data Pribadi Anda hanya selama yang diperlukan untuk tujuan yang ditetapkan dalam Kebijakan Privasi ini. Kami akan menyimpan dan menggunakan Data Pribadi Anda sejauh yang diperlukan untuk mematuhi kewajiban hukum kami (misalnya, jika kami diwajibkan menyimpan data Anda untuk mematuhi hukum yang berlaku), menyelesaikan perselisihan, dan menegakkan perjanjian dan kebijakan hukum kami.</p>
            <p>Perusahaan juga akan menyimpan Data Pengguna untuk tujuan analisis internal. Data Pengguna umumnya disimpan untuk jangka waktu yang lebih singkat, kecuali ketika data ini digunakan untuk memperkuat keamanan atau meningkatkan fungsionalitas Layanan Kami, atau kami diwajibkan secara hukum untuk menyimpan data ini selama periode waktu yang lebih lama.</p>
            <h3>Transfer Data Pribadi Anda</h3>
            <p>Informasi Anda, termasuk Data Pribadi, diproses di kantor operasional Perusahaan dan di tempat lain di mana pihak yang terlibat dalam pemrosesan tersebut berada. Ini berarti bahwa informasi ini dapat ditransfer ke - dan dipelihara di - komputer yang berada di luar negara bagian, provinsi, negara, atau yurisdiksi pemerintahan lain di mana undang-undang perlindungan data dapat berbeda dengan yang berlaku di yurisdiksi Anda.</p>
            <p>Persetujuan Anda terhadap Kebijakan Privasi ini yang diikuti oleh pengiriman informasi tersebut mewakili persetujuan Anda terhadap transfer tersebut.</p>
            <p>Perusahaan akan mengambil semua langkah yang diperlukan dengan wajar untuk memastikan bahwa data Anda diperlakukan dengan aman dan sesuai dengan Kebijakan Privasi ini dan tidak akan ada transfer Data Pribadi Anda ke organisasi atau negara kecuali ada kontrol yang memadai termasuk keamanan data Anda dan informasi pribadi lainnya.</p>
            <h3>Hapus Data Pribadi Anda</h3>
            <p>Anda memiliki hak untuk menghapus atau meminta bantuan Kami untuk menghapus Data Pribadi yang telah Kami kumpulkan tentang Anda.</p>
            <p>Layanan Kami dapat memberi Anda kemampuan untuk menghapus beberapa informasi tentang Anda dari dalam Layanan.</p>
            <p>Anda dapat memperbarui, mengubah, atau menghapus informasi Anda kapan saja dengan masuk ke Akun Anda, jika Anda memiliki satu, dan mengunjungi bagian pengaturan akun yang memungkinkan Anda mengelola informasi pribadi Anda. Anda juga dapat menghubungi Kami untuk meminta akses, koreksi, atau penghapusan informasi pribadi apa pun yang telah Anda berikan kepada Kami.</p>
            <p>Namun, perlu diingat bahwa Kami mungkin perlu menyimpan beberapa informasi ketika Kami memiliki kewajiban hukum atau dasar hukum untuk melakukannya.</p>
            <h3>Penyampaian Data Pribadi Anda</h3>
            <h4>Transaksi Bisnis</h4>
            <p>Jika Perusahaan terlibat dalam penggabungan, akuisisi, atau penjualan aset, Data Pribadi Anda dapat ditransfer. Kami akan memberikan pemberitahuan sebelum Data Pribadi Anda ditransfer dan menjadi tunduk pada Kebijakan Privasi yang berbeda.</p>
            <h4>Penegakan hukum</h4>
            <p>Pada keadaan tertentu, Perusahaan dapat diwajibkan untuk mengungkap Data Pribadi Anda jika diharuskan melakukannya oleh hukum atau sebagai respons terhadap permintaan yang valid oleh otoritas publik (misalnya, pengadilan atau lembaga pemerintah).</p>
            <h4>Persyaratan hukum lainnya</h4>
            <p>Perusahaan dapat mengungkap Data Pribadi Anda dengan itikad baik bahwa tindakan tersebut diperlukan untuk:</p>
            <ul>
                <li>Mematuhi kewajiban hukum</li>
                <li>Melindungi dan mempertahankan hak atau properti Perusahaan</li>
                <li>Mencegah atau menyelidiki tindakan melanggar hukum yang mungkin terjadi dalam kaitannya dengan Layanan</li>
                <li>Memelihara keselamatan pribadi Pengguna Layanan atau masyarakat</li>
                <li>Perlindungan terhadap tanggung jawab hukum</li>
            </ul>
            <h3>Keamanan Data Pribadi Anda</h3>
            <p>Keamanan Data Pribadi Anda penting bagi Kami, namun ingatlah bahwa tidak ada metode transmisi melalui Internet, atau metode penyimpanan elektronik yang 100% aman. Meskipun Kami berusaha menggunakan cara yang dapat diterima secara komersial untuk melindungi Data Pribadi Anda, Kami tidak dapat menjamin keamanannya secara mutlak.</p>
            <h2>Privasi Anak-Anak</h2>
            <p>Layanan Kami tidak ditujukan untuk siapa pun di bawah usia 13 tahun. Kami tidak sengaja mengumpulkan informasi identifikasi pribadi dari siapa pun di bawah usia 13 tahun. Jika Anda adalah orang tua atau wali dan menyadari bahwa anak Anda telah memberikan Data Pribadi kepada Kami, harap hubungi Kami. Jika Kami mengetahui bahwa Kami telah mengumpulkan Data Pribadi dari siapa pun di bawah usia 13 tahun tanpa verifikasi persetujuan orang tua, Kami akan mengambil langkah untuk menghapus informasi tersebut dari server Kami.</p>
            <p>Jika Kami perlu mengandalkan persetujuan sebagai dasar hukum untuk memproses informasi Anda dan negara Anda memerlukan persetujuan dari orang tua, Kami mungkin memerlukan persetujuan orang tua Anda sebelum Kami mengumpulkan dan menggunakan informasi tersebut.</p>
            <h2>Tautan ke Situs Lain</h2>
            <p>Layanan Kami dapat mengandung tautan ke situs web lain yang tidak dioperasikan oleh Kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi setiap situs yang Anda kunjungi.</p>
            <p>Kami tidak memiliki kontrol atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik dari situs atau layanan pihak ketiga.</p>
            <h2>Perubahan pada Kebijakan Privasi Ini</h2>
            <p>Kami dapat memperbarui Kebijakan Privasi Kami dari waktu ke waktu. Kami akan memberi tahu Anda tentang setiap perubahan dengan memposting Kebijakan Privasi baru ini di halaman ini.</p>
            <p>Kami akan memberi tahu Anda melalui email dan/atau pemberitahuan mencolok di Layanan Kami, sebelum perubahan tersebut mulai berlaku, dan memperbarui tanggal "Terakhir diperbarui" di bagian atas Kebijakan Privasi ini.</p>
            <p>Kami menyarankan Anda untuk secara berkala meninjau Kebijakan Privasi ini untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini efektif saat diposting di halaman ini.</p>
            <h2>Hubungi Kami</h2>
            <p>Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, Anda dapat menghubungi kami:</p>
            <ul>
                <li>Melalui email: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="066f68606946677372692b62686370686f6d287473">[email&nbsp;protected]</a></li>
            </ul>

        </div>
    )
}

export default PrivacyPageIn;