function PrivacyPageDe() {

    return (
        <div className="privacy-page">
            <h1>Datenschutzrichtlinie</h1>
            <p>Zuletzt aktualisiert: 10. November 2023</p>
            <p>Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und Verfahren zur Erhebung, Nutzung und Offenlegung Ihrer Informationen bei der Nutzung des Dienstes und informiert Sie über Ihre Datenschutzrechte sowie darüber, wie das Gesetz Sie schützt.</p>
            <p>Wir verwenden Ihre persönlichen Daten, um den Dienst bereitzustellen und zu verbessern. Durch die Nutzung des Dienstes erklären Sie sich mit der Sammlung und Verwendung von Informationen gemäß dieser Datenschutzrichtlinie einverstanden. Diese Datenschutzrichtlinie wurde mithilfe des <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Privacy Policy Generator</a> erstellt.</p>
            <h2>Auslegung und Definitionen</h2>
            <h3>Auslegung</h3>
            <p>Die Wörter, deren Anfangsbuchstabe groß geschrieben ist, haben unter den folgenden Bedingungen definierte Bedeutungen. Die folgenden Definitionen haben die gleiche Bedeutung, unabhängig davon, ob sie im Singular oder Plural erscheinen.</p>
            <h3>Definitionen</h3>
            <p>Zwecks dieser Datenschutzrichtlinie gelten folgende Definitionen:</p>
            <ul>
                <li>
                    <p><strong>Konto</strong> bedeutet ein eindeutiges Konto, das für Sie erstellt wurde, um auf unseren Dienst oder Teile unseres Dienstes zuzugreifen.</p>
                </li>
                <li>
                    <p><strong>Affiliate</strong> bedeutet eine Einrichtung, die eine Kontrolle über eine Partei ausübt, von einer Partei kontrolliert wird oder unter gemeinsamer Kontrolle mit einer Partei steht, wobei "Kontrolle" den Besitz von 50% oder mehr der Aktien, Beteiligungen oder anderer Wertpapiere bedeutet, die zur Abstimmung über die Wahl von Direktoren oder anderer leitender Organe berechtigt sind.</p>
                </li>
                <li>
                    <p><strong>Anwendung</strong> bezieht sich auf Auto-Diary, das von der Firma bereitgestellte Softwareprogramm.</p>
                </li>
                <li>
                    <p><strong>Firma</strong> (im Rahmen dieser Vereinbarung als "die Firma", "Wir", "Uns" oder "Unser" bezeichnet) bezieht sich auf Auto-Diary.</p>
                </li>
                <li>
                    <p><strong>Land</strong> bezieht sich auf: Russland</p>
                </li>
                <li>
                    <p><strong>Gerät</strong> bedeutet jedes Gerät, das auf den Dienst zugreifen kann, wie Computer, Mobiltelefon oder Tablet.</p>
                </li>
                <li>
                    <p><strong>Persönliche Daten</strong> sind alle Informationen, die sich auf eine identifizierte oder identifizierbare Person beziehen.</p>
                </li>
                <li>
                    <p><strong>Dienst</strong> bezieht sich auf die Anwendung.</p>
                </li>
                <li>
                    <p><strong>Dienstleister</strong> bedeutet jede natürliche oder juristische Person, die die Daten im Auftrag der Firma verarbeitet. Dies bezieht sich auf Drittunternehmen oder Einzelpersonen, die von der Firma beauftragt sind, den Dienst zu erleichtern, den Dienst im Auftrag der Firma bereitzustellen, Dienstleistungen im Zusammenhang mit dem Dienst durchzuführen oder der Firma bei der Analyse der Nutzung des Dienstes zu helfen.</p>
                </li>
                <li>
                    <p><strong>Nutzungsdaten</strong> bezieht sich auf automatisch gesammelte Daten, die entweder durch die Nutzung des Dienstes oder durch die Dienstinfrastruktur selbst generiert werden (z. B. die Dauer eines Seitenbesuchs).</p>
                </li>
                <li>
                    <p><strong>Sie</strong> bedeutet die Person, die auf den Dienst zugreift oder diesen nutzt, oder das Unternehmen oder eine andere juristische Person, die diese Person bei der Nutzung oder Nutzung des Dienstes vertritt, falls zutreffend.</p>
                </li>
            </ul>
            <h2>Erhebung und Nutzung Ihrer persönlichen Daten</h2>
            <h3>Arten der gesammelten Daten</h3>
            <h4>Persönliche Daten</h4>
            <p>Beim Verwenden unseres Dienstes können wir Sie bitten, uns bestimmte persönlich identifizierbare Informationen zur Verfügung zu stellen, die dazu verwendet werden können, Sie zu kontaktieren oder zu identifizieren. Persönlich identifizierbare Informationen können unter anderem Folgendes umfassen:</p>
            <ul>
                <li>
                    <p>E-Mail-Adresse</p>
                </li>
                <li>
                    <p>Nutzungsdaten</p>
                </li>
            </ul>
            <h4>Nutzungsdaten</h4>
            <p>Nutzungsdaten werden automatisch beim Gebrauch des Dienstes gesammelt.</p>
            <p>Nutzungsdaten können Informationen wie die Internetprotokolladresse Ihres Geräts (z. B. IP-Adresse), den Browsertyp, die Browserversion, die Seiten unseres Dienstes, die Sie besuchen, die Uhrzeit und das Datum Ihres Besuchs, die auf diesen Seiten verbrachte Zeit, eindeutige Gerätekennungen und andere diagnostische Daten umfassen.</p>
            <p>Wenn Sie auf den Dienst über ein Mobilgerät zugreifen, können wir automatisch bestimmte Informationen sammeln, einschließlich, aber nicht beschränkt auf, den Typ des von Ihnen verwendeten Mobilgeräts, Ihre eindeutige Gerätekennung, die IP-Adresse Ihres Mobilgeräts, Ihr mobiles Betriebssystem, den Typ des von Ihnen verwendeten mobilen Internetbrowsers, eindeutige Gerätekennungen und andere diagnostische Daten.</p>
            <p>Wir können auch Informationen sammeln, die Ihr Browser jedes Mal sendet, wenn Sie unseren Dienst besuchen oder wenn Sie auf den Dienst über ein Mobilgerät zugreifen.</p>
            <h4>Informationen, die bei der Verwendung der Anwendung gesammelt werden</h4>
            <p>Beim Verwenden unserer Anwendung können wir mit Ihrer vorherigen Zustimmung folgende Informationen sammeln, um Funktionen unserer Anwendung bereitzustellen:</p>
            <ul>
                <li>
                    <p>Informationen zu Ihrem Standort</p>
                </li>
                <li>
                    <p>Bilder und andere Informationen aus der Kamera und Fotobibliothek Ihres Geräts</p>
                </li>
            </ul>
            <p>Wir verwenden diese Informationen, um Funktionen unseres Dienstes bereitzustellen, unseren Dienst zu verbessern und anzupassen. Die Informationen können auf die Server des Unternehmens und/oder auf einen Server des Dienstleisters hochgeladen werden oder sie können einfach auf Ihrem Gerät gespeichert werden.</p>
            <p>Sie können den Zugriff auf diese Informationen jederzeit über die Einstellungen Ihres Geräts aktivieren oder deaktivieren.</p>
            <h3>Verwendung Ihrer persönlichen Daten</h3>
            <p>Die Firma kann persönliche Daten für folgende Zwecke verwenden:</p>
            <ul>
                <li>
                    <p><strong>Um unseren Dienst bereitzustellen und aufrechtzuerhalten</strong>, einschließlich der Überwachung der Nutzung unseres Dienstes.</p>
                </li>
                <li>
                    <p><strong>Ihr Konto zu verwalten:</strong> Um Ihre Registrierung als Benutzer des Dienstes zu verwalten. Die von Ihnen bereitgestellten persönlichen Daten ermöglichen Ihnen den Zugang zu verschiedenen Funktionen des Dienstes, die Ihnen als registriertem Benutzer zur Verfügung stehen.</p>
                </li>
                <li>
                    <p><strong>Zur Erfüllung eines Vertrags:</strong> Die Entwicklung, Einhaltung und Durchführung des Kaufvertrags für die von Ihnen gekauften Produkte, Artikel oder Dienstleistungen oder eines anderen Vertrags mit uns über den Dienst.</p>
                </li>
                <li>
                    <p><strong>Sie zu kontaktieren:</strong> Um Sie per E-Mail, Telefonanrufe, SMS oder andere gleichwertige Formen elektronischer Kommunikation zu kontaktieren, wie beispielsweise Push-Benachrichtigungen einer mobilen Anwendung, bezüglich Aktualisierungen oder informativen Mitteilungen im Zusammenhang mit den Funktionen, Produkten oder vertraglich vereinbarten Dienstleistungen, einschließlich Sicherheitsupdates, wenn dies für ihre Umsetzung notwendig oder vernünftig ist.</p>
                </li>
                <li>
                    <p><strong>Ihnen Nachrichten, Sonderangebote und allgemeine Informationen zu bieten</strong> über andere Waren, Dienstleistungen und Veranstaltungen, die wir anbieten und die denen ähnlich sind, die Sie bereits erworben oder angefragt haben, es sei denn, Sie haben sich dafür entschieden, solche Informationen nicht zu erhalten.</p>
                </li>
                <li>
                    <p><strong>Ihre Anfragen zu verwalten:</strong> Um Ihre Anfragen an uns zu bearbeiten und zu verwalten.</p>
                </li>
                <li>
                    <p><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre Informationen verwenden, um eine Fusion, Veräußerung, Umstrukturierung, Reorganisation, Auflösung oder einen anderen Verkauf oder Transfer von Teilen oder allen unseren Vermögenswerten zu bewerten oder durchzuführen, sei es als laufendes Geschäft oder im Rahmen eines Insolvenz-, Liquidations- oder ähnlichen Verfahrens, bei dem personenbezogene Daten, die wir über die Benutzer unseres Dienstes haben, zu den übertragenen Vermögenswerten gehören.</p>
                </li>
                <li>
                    <p><strong>Für andere Zwecke:</strong> Wir können Ihre Informationen für andere Zwecke verwenden, wie z. B. für Datenanalysen, Identifizierung von Nutzungstrends, Bestimmung der Wirksamkeit unserer Werbekampagnen und zur Bewertung und Verbesserung unseres Dienstes, unserer Produkte, Dienstleistungen, Marketingmaßnahmen und Ihrer Erfahrung.</p>
                </li>
            </ul>
            <p>Wir können Ihre persönlichen Informationen in folgenden Situationen teilen:</p>
            <ul>
                <li><strong>Mit Dienstleistern:</strong> Wir können Ihre persönlichen Informationen mit Dienstleistern teilen, um die Nutzung unseres Dienstes zu überwachen und zu analysieren, um Sie zu kontaktieren.</li>
                <li><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre persönlichen Informationen im Zusammenhang mit einer Fusion, dem Verkauf von Unternehmensvermögen, Finanzierung oder dem Erwerb unseres gesamten Geschäfts oder eines Teils davon an ein anderes Unternehmen teilen oder übertragen.</li>
                <li><strong>Mit Tochtergesellschaften:</strong> Wir können Ihre Informationen mit unseren Tochtergesellschaften teilen, in welchem Fall wir von diesen verlangen werden, diese Datenschutzrichtlinie zu respektieren. Tochtergesellschaften umfassen unsere Muttergesellschaft und andere Tochtergesellschaften, Joint-Venture-Partner oder andere Unternehmen, die von uns kontrolliert werden oder unter gemeinsamer Kontrolle mit uns stehen.</li>
                <li><strong>Mit Geschäftspartnern:</strong> Wir können Ihre Informationen mit unseren Geschäftspartnern teilen, um Ihnen bestimmte Produkte, Dienstleistungen oder Aktionen anzubieten.</li>
                <li><strong>Mit anderen Benutzern:</strong> Wenn Sie persönliche Informationen teilen oder anderweitig in den öffentlichen Bereichen mit anderen Benutzern interagieren, können diese Informationen von allen Benutzern eingesehen und öffentlich verbreitet werden.</li>
                <li><strong>Mit Ihrer Zustimmung:</strong> Wir können Ihre persönlichen Informationen zu jedem anderen Zweck mit Ihrer Zustimmung offenlegen.</li>
            </ul>
            <h3>Speicherung Ihrer persönlichen Daten</h3>
            <p>Das Unternehmen wird Ihre persönlichen Daten nur so lange aufbewahren, wie es für die in dieser Datenschutzrichtlinie festgelegten Zwecke erforderlich ist. Wir werden Ihre persönlichen Daten aufbewahren und verwenden, soweit dies notwendig ist, um unseren gesetzlichen Verpflichtungen nachzukommen (zum Beispiel, wenn wir verpflichtet sind, Ihre Daten zur Einhaltung geltender Gesetze aufzubewahren), Streitigkeiten beizulegen und unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.</p>
            <p>Das Unternehmen wird auch Nutzungsdaten zu internen Analysezwecken aufbewahren. Nutzungsdaten werden in der Regel für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese Daten werden zur Stärkung der Sicherheit oder zur Verbesserung der Funktionalität unseres Dienstes verwendet, oder wir sind gesetzlich verpflichtet, diese Daten für längere Zeiträume aufzubewahren.</p>
            <h3>Übertragung Ihrer persönlichen Daten</h3>
            <p>Ihre Informationen, einschließlich persönlicher Daten, werden in den Betriebsbüros des Unternehmens und an anderen Orten, an denen die an der Verarbeitung beteiligten Parteien sich befinden, verarbeitet. Das bedeutet, dass diese Informationen möglicherweise an Computer außerhalb Ihres Bundeslandes, Ihrer Provinz, Ihres Landes oder einer anderen staatlichen Gerichtsbarkeit übertragen und dort aufbewahrt werden, wo die Datenschutzgesetze von denen in Ihrer Gerichtsbarkeit abweichen können.</p>
            <p>Ihre Zustimmung zu dieser Datenschutzrichtlinie, gefolgt von Ihrer Übermittlung solcher Informationen, stellt Ihre Zustimmung zu dieser Übertragung dar.</p>
            <p>Das Unternehmen wird alle erforderlichen Schritte unternehmen, um sicherzustellen, dass Ihre Daten sicher und in Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden, und keine Übertragung Ihrer persönlichen Daten an eine Organisation oder ein Land erfolgt, es sei denn, es liegen angemessene Kontrollen vor, einschließlich der Sicherheit Ihrer Daten und anderer persönlicher Informationen.</p>
            <h3>Löschen Ihrer persönlichen Daten</h3>
            <p>Sie haben das Recht, die Löschung oder die Unterstützung bei der Löschung der von uns über Sie gesammelten persönlichen Daten zu verlangen.</p>
            <p>Unser Dienst kann Ihnen die Möglichkeit geben, bestimmte Informationen über Sie innerhalb des Dienstes zu löschen.</p>
            <p>Sie können Ihre Informationen jederzeit aktualisieren, ändern oder löschen, indem Sie sich in Ihr Konto einloggen, falls vorhanden, und den Abschnitt Kontoeinstellungen besuchen, der es Ihnen ermöglicht, Ihre persönlichen Informationen zu verwalten. Sie können sich auch an uns wenden, um Zugang zu, Korrektur oder Löschung aller von Ihnen bereitgestellten persönlichen Informationen zu beantragen.</p>
            <p>Bitte beachten Sie jedoch, dass wir bestimmte Informationen aufgrund gesetzlicher Verpflichtungen oder rechtlicher Grundlagen aufbewahren müssen.</p>
            <h3>Offenlegung Ihrer persönlichen Daten</h3>
            <h4>Unternehmensübertragungen</h4>
            <p>Wenn das Unternehmen an einer Fusion, einem Erwerb oder Verkauf von Vermögenswerten beteiligt ist, können Ihre persönlichen Daten übertragen werden. Wir werden Sie benachrichtigen, bevor Ihre persönlichen Daten übertragen werden und einer anderen Datenschutzrichtlinie unterliegen.</p>
            <h4>Strafverfolgung</h4>
            <p>Unter bestimmten Umständen kann das Unternehmen verpflichtet sein, Ihre persönlichen Daten offenzulegen, wenn dies gesetzlich vorgeschrieben ist oder aufgrund gültiger Anfragen von öffentlichen Behörden erforderlich ist (z.B. Gericht oder Regierungsbehörde).</p>
            <h4>Weitere rechtliche Anforderungen</h4>
            <p>Das Unternehmen kann Ihre persönlichen Daten in gutem Glauben offenlegen, dass eine solche Handlung notwendig ist, um:</p>
            <ul>
                <li>einer gesetzlichen Verpflichtung nachzukommen</li>
                <li>die Rechte oder das Eigentum des Unternehmens zu schützen und zu verteidigen</li>
                <li>mögliche rechtswidrige Handlungen im Zusammenhang mit dem Dienst zu verhindern oder zu untersuchen</li>
                <li>die persönliche Sicherheit der Benutzer des Dienstes oder der Öffentlichkeit zu schützen</li>
                <li>sich gegen rechtliche Haftung zu schützen</li>
            </ul>
            <h3>Sicherheit Ihrer persönlichen Daten</h3>
            <p>Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber bitte beachten Sie, dass keine Übertragung über das Internet oder elektronische Speicherung zu 100 % sicher ist. Wir bemühen uns, kommerziell akzeptable Mittel zu verwenden, um Ihre persönlichen Daten zu schützen, können jedoch deren absolute Sicherheit nicht garantieren.</p>
            <h2>Datenschutz von Kindern</h2>
            <p>Unser Dienst richtet sich nicht an Personen unter 13 Jahren. Wir sammeln wissentlich keine personenbezogenen Informationen von Personen unter 13 Jahren. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind und feststellen, dass Ihr Kind uns personenbezogene Daten bereitgestellt hat, kontaktieren Sie uns bitte. Wenn uns bekannt wird, dass wir personenbezogene Daten von Personen unter 13 Jahren ohne Überprüfung der elterlichen Zustimmung gesammelt haben, ergreifen wir Maßnahmen, um diese Informationen von unseren Servern zu entfernen.</p>
            <p>Wenn wir uns auf Zustimmung als rechtliche Grundlage für die Verarbeitung Ihrer Informationen stützen müssen und Ihr Land eine Zustimmung von einem Elternteil erfordert, können wir die Zustimmung Ihres Elternteils einholen, bevor wir diese Informationen sammeln und verwenden.</p>
            <h2>Links zu anderen Websites</h2>
            <p>Unser Dienst kann Links zu anderen Websites enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link von Drittanbietern klicken, werden Sie zu der Website dieses Drittanbieters weitergeleitet. Wir empfehlen Ihnen dringend, die Datenschutzrichtlinie jeder Website zu überprüfen, die Sie besuchen.</p>
            <p>Wir haben keine Kontrolle über und übernehmen keine Verantwortung für den Inhalt, die Datenschutzrichtlinien oder Praktiken von Websites oder Diensten Dritter.</p>
            <h2>Änderungen dieser Datenschutzrichtlinie</h2>
            <p>Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über Änderungen informieren, indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.</p>
            <p>Wir werden Sie per E-Mail und/oder durch einen prominenten Hinweis auf unserem Dienst vor der Wirksamkeit der Änderung informieren und das Datum "Zuletzt aktualisiert" oben auf dieser Datenschutzrichtlinie aktualisieren.</p>
            <p>Sie werden darauf hingewiesen, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu überprüfen. Änderungen dieser Datenschutzrichtlinie sind wirksam, wenn sie auf dieser Seite veröffentlicht werden.</p>
            <h2>Kontaktieren Sie uns</h2>
            <p>Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie uns kontaktieren:</p>
            <ul>
                <li>Per E-Mail: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="066f68606946677372692b62686370686f6d287473">[email&nbsp;protected]</a></li>
            </ul>

        </div>
    )
}

export default PrivacyPageDe;