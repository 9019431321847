function PrivacyPageEs() {

    return (
        <div className="privacy-page">
            <h1>Política de Privacidad</h1>
            <p>Última actualización: 10 de noviembre de 2023</p>
            <p>Esta Política de Privacidad describe nuestras políticas y procedimientos sobre la recopilación, uso y divulgación de su información cuando utiliza el Servicio, y le informa sobre sus derechos de privacidad y cómo la ley lo protege.</p>
            <p>Utilizamos sus datos personales para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta Política de Privacidad. Esta Política de Privacidad ha sido creada con la ayuda del <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">Generador de Políticas de Privacidad</a>.</p>
            <h2>Interpretación y Definiciones</h2>
            <h3>Interpretación</h3>
            <p>Las palabras cuya inicial está en mayúscula tienen significados definidos en las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de si aparecen en singular o en plural.</p>
            <h3>Definiciones</h3>
            <p>Para los fines de esta Política de Privacidad:</p>
            <ul>
                <li>
                    <p><strong>Cuenta</strong> significa una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Afiliado</strong> significa una entidad que controla, es controlada por o está bajo control común con una parte, donde "control" significa la propiedad del 50% o más de las acciones, participación patrimonial u otros valores con derecho a voto para la elección de directores u otra autoridad de gestión.</p>
                </li>
                <li>
                    <p><strong>Aplicación</strong> se refiere a Auto-Diary, el programa de software proporcionado por la Empresa.</p>
                </li>
                <li>
                    <p><strong>Empresa</strong> (referida como "la Empresa", "Nosotros", "Nuestro" o "Nuestra" en este Acuerdo) se refiere a Auto-Diary.</p>
                </li>
                <li>
                    <p><strong>País</strong> se refiere a: Rusia</p>
                </li>
                <li>
                    <p><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</p>
                </li>
                <li>
                    <p><strong>Datos Personales</strong> son cualquier información que se relacione con un individuo identificado o identificable.</p>
                </li>
                <li>
                    <p><strong>Servicio</strong> se refiere a la Aplicación.</p>
                </li>
                <li>
                    <p><strong>Prestador de Servicios</strong> significa cualquier persona natural o jurídica que procesa los datos en nombre de la Empresa. Se refiere a empresas o individuos de terceros empleados por la Empresa para facilitar el Servicio, proporcionar el Servicio en nombre de la Empresa, realizar servicios relacionados con el Servicio o ayudar a la Empresa en el análisis de cómo se utiliza el Servicio.</p>
                </li>
                <li>
                    <p><strong>Datos de Uso</strong> se refiere a datos recopilados automáticamente, ya sea generados por el uso del Servicio o desde la infraestructura del Servicio misma (por ejemplo, la duración de una visita a una página).</p>
                </li>
                <li>
                    <p><strong>Usted</strong> significa el individuo que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicho individuo accede o utiliza el Servicio, según corresponda.</p>
                </li>
            </ul>
            <h2>Recopilación y Uso de Sus Datos Personales</h2>
            <h3>Tipos de Datos Recopilados</h3>
            <h4>Datos Personales</h4>
            <p>Mientras utiliza Nuestro Servicio, es posible que le pidamos que nos proporcione cierta información de identificación personal que se puede utilizar para contactarlo o identificarlo. La información de identificación personal puede incluir, pero no se limita a:</p>
            <ul>
                <li>
                    <p>Dirección de correo electrónico</p>
                </li>
                <li>
                    <p>Datos de Uso</p>
                </li>
            </ul>
            <h4>Datos de Uso</h4>
            <p>Los Datos de Uso se recopilan automáticamente al utilizar el Servicio.</p>
            <p>Los Datos de Uso pueden incluir información como la dirección de Protocolo de Internet de su Dispositivo (por ejemplo, dirección IP), tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, identificadores de dispositivos únicos y otros datos de diagnóstico.</p>
            <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, incluido, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores de dispositivos únicos y otros datos de diagnóstico.</p>
            <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>
            <h4>Información Recopilada Mientras Usa la Aplicación</h4>
            <p>Mientras utiliza Nuestra Aplicación, con el fin de proporcionar características de Nuestra Aplicación, podemos recopilar, con su permiso previo:</p>
            <ul>
                <li>
                    <p>Información sobre su ubicación</p>
                </li>
                <li>
                    <p>Fotos y otra información de la cámara y la biblioteca de fotos de su Dispositivo</p>
                </li>
            </ul>
            <p>Utilizamos esta información para proporcionar características de Nuestro Servicio, para mejorar y personalizar Nuestro Servicio. La información puede cargarse en los servidores de la Empresa y/o en los servidores de un Proveedor de Servicios, o simplemente puede almacenarse en su dispositivo.</p>
            <p>Puede habilitar o deshabilitar el acceso a esta información en cualquier momento, a través de la configuración de su Dispositivo.</p>
            <h3>Uso de Sus Datos Personales</h3>
            <p>La Empresa puede utilizar Datos Personales para los siguientes fines:</p>
            <ul>
                <li>
                    <p><strong>Proporcionar y mantener nuestro Servicio</strong>, incluido el monitoreo del uso de nuestro Servicio.</p>
                </li>
                <li>
                    <p><strong>Administrar su Cuenta:</strong> para gestionar su registro como usuario del Servicio. Los Datos Personales que proporciona pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.</p>
                </li>
                <li>
                    <p><strong>Para el cumplimiento de un contrato:</strong> el desarrollo, cumplimiento y ejecución del contrato de compra de los productos, artículos o servicios que ha adquirido, o de cualquier otro contrato con nosotros a través del Servicio.</p>
                </li>
                <li>
                    <p><strong>Contactarlo:</strong> Para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones push de aplicaciones móviles sobre actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</p>
                </li>
                <li>
                    <p><strong>Proporcionarle</strong> noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos similares a los que ya ha comprado o consultado, a menos que haya optado por no recibir dicha información.</p>
                </li>
                <li>
                    <p><strong>Administrar sus solicitudes:</strong> Atender y gestionar sus solicitudes hacia nosotros.</p>
                </li>
                <li>
                    <p><strong>Para transferencias comerciales:</strong> Podemos utilizar su información para evaluar o llevar a cabo una fusión, venta de activos, financiamiento, o adquisición de todo o parte de nuestro negocio a otra empresa.</p>
                </li>
                <li>
                    <p><strong>Para otros fines</strong>: Podemos utilizar su información para otros fines, como análisis de datos, identificación de tendencias de uso, determinación de la efectividad de nuestras campañas promocionales y para evaluar y mejorar nuestro Servicio, productos, servicios, marketing y su experiencia.</p>
                </li>
            </ul>
            <p>Podemos compartir su información personal en las siguientes situaciones:</p>
            <ul>
                <li><strong>Con proveedores de servicios:</strong> Podemos compartir su información personal con proveedores de servicios para monitorear y analizar el uso de nuestro Servicio, y para ponernos en contacto con usted.</li>
                <li><strong>Para transferencias comerciales:</strong> Podemos compartir o transferir su información personal en relación con, o durante negociaciones de, cualquier fusión, venta de activos de la empresa, financiamiento o adquisición de todo o parte de nuestro negocio a otra empresa.</li>
                <li><strong>Con afiliados:</strong> Podemos compartir su información con nuestros afiliados, en cuyo caso requeriremos que esos afiliados respeten esta Política de privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlemos o que estén bajo el control común con nosotros.</li>
                <li><strong>Con socios comerciales:</strong> Podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</li>
                <li><strong>Con otros usuarios:</strong> cuando comparta información personal o interactúe en áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente.</li>
                <li><strong>Con su consentimiento</strong>: Podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
            </ul>
            <h3>Retención de sus datos personales</h3>
            <p>La empresa retendrá sus datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Retendremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a retener sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestros acuerdos legales y políticas.</p>
            <p>La empresa también retendrá los datos de uso con fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando se utiliza para fortalecer la seguridad o mejorar la funcionalidad de nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>
            <h3>Transferencia de sus datos personales</h3>
            <p>Su información, incluidos los datos personales, se procesa en las oficinas operativas de la empresa y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede ser transferida a, y mantenida en, computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</p>
            <p>Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.</p>
            <p>La empresa tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de manera segura y de acuerdo con esta Política de privacidad, y ninguna transferencia de sus datos personales se llevará a cabo a una organización o país a menos que haya controles adecuados, incluida la seguridad de sus datos e información personal.</p>
            <h3>Eliminar sus datos personales</h3>
            <p>Tiene el derecho de eliminar o solicitar que le ayudemos a eliminar los datos personales que hemos recopilado sobre usted.</p>
            <p>Nuestro Servicio puede brindarle la capacidad de eliminar cierta información sobre usted desde dentro del Servicio.</p>
            <p>Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su cuenta, si la tiene, y visitando la sección de configuración de la cuenta que le permite gestionar su información personal. También puede ponerse en contacto con nosotros para solicitar acceso, corrección o eliminación de cualquier información personal que nos haya proporcionado.</p>
            <p>Tenga en cuenta, sin embargo, que es posible que necesitemos retener cierta información cuando tengamos una obligación legal o base legal para hacerlo.</p>
            <h3>Divulgación de sus datos personales</h3>
            <h4>Transacciones comerciales</h4>
            <p>Si la empresa está involucrada en una fusión, adquisición o venta de activos, sus datos personales pueden ser transferidos. Le proporcionaremos aviso antes de que sus datos personales se transfieran y estén sujetos a una Política de privacidad diferente.</p>
            <h4>Aplicación de la ley</h4>
            <p>Bajo ciertas circunstancias, la empresa puede estar obligada a divulgar sus datos personales si así lo requiere la ley o en respuesta a solicitudes válidas de autoridades públicas (por ejemplo, un tribunal o una agencia gubernamental).</p>
            <h4>Otros requisitos legales</h4>
            <p>La empresa puede divulgar sus datos personales de buena fe cuando dicha acción sea necesaria para:</p>
            <ul>
                <li>Cumplir con una obligación legal</li>
                <li>Proteger y defender los derechos o propiedades de la empresa</li>
                <li>Prevenir o investigar posibles conductas indebidas en relación con el Servicio</li>
                <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
                <li>Proteger contra responsabilidad legal</li>
            </ul>
            <h3>Seguridad de sus datos personales</h3>
            <p>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o almacenamiento electrónico es 100% seguro. Aunque nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</p>
            <h2>Privacidad de los niños</h2>
            <p>Nuestro Servicio no se dirige a personas menores de 13 años. No recopilamos conscientemente información de identificación personal de personas menores de 13 años. Si es padre o tutor y tiene conocimiento de que su hijo nos ha proporcionado datos personales, contáctenos. Si nos damos cuenta de que hemos recopilado datos personales de personas menores de 13 años sin verificar el consentimiento parental, tomamos medidas para eliminar esa información de nuestros servidores.</p>
            <p>Si necesitamos depender del consentimiento como base legal para el procesamiento de su información y su país requiere el consentimiento de un padre, podemos requerir el consentimiento de sus padres antes de recopilar y usar esa información.</p>
            <h2>Enlaces a otros sitios web</h2>
            <p>Nuestro Servicio puede contener enlaces a otros sitios web que no son operados por nosotros. Si hace clic en un enlace de terceros, se le dirigirá al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</p>
            <p>No tenemos control sobre el contenido, las políticas de privacidad o las prácticas de ningún sitio o servicio de terceros.</p>
            <h2>Cambios en esta Política de privacidad</h2>
            <p>Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.</p>
            <p>Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la fecha de "Última actualización" en la parte superior de esta Política de privacidad.</p>
            <p>Se recomienda revisar periódicamente esta Política de privacidad para cualquier cambio. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</p>
            <h2>Contáctenos</h2>
            <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede ponerse en contacto con nosotros:</p>
            <ul>
                <li>Por correo electrónico: <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="066f68606946677372692b62686370686f6d287473">[email&nbsp;protected]</a></li>
            </ul>

        </div>
    )
}

export default PrivacyPageEs;