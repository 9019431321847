import { Route, Routes } from "react-router-dom";

import MainPage from "./components/MainPage/MainPage";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import PrivacyPageRu from "./components/PrivacyPage/PrivacyPageRu";
import PrivacyPageEn from "./components/PrivacyPage/PrivacyPageEn";
import PrivacyPagePt from "./components/PrivacyPage/PrivacyPagePt";
import PrivacyPageEs from "./components/PrivacyPage/PrivacyPageEs";
import PrivacyPageDe from "./components/PrivacyPage/PrivacyPageDe";
import PrivacyPageFr from "./components/PrivacyPage/PrivacyPageFr";
import PrivacyPageIn from "./components/PrivacyPage/PrivacyPageIn";
import RemoveProfile from "./components/RemoveProfile/RemoveProfile";
import MeterReadings from "./components/MeterReadings/MeterReadings";

function App() {

    return (
        <main className="app">
            <Routes>
                {/* Главная страница */}
                <Route path="/" element={<MainPage />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-ru" element={<PrivacyPageRu />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-en" element={<PrivacyPageEn />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-pt" element={<PrivacyPagePt />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-es" element={<PrivacyPageEs />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-de" element={<PrivacyPageDe />} />
                {/* Политика обработки конфиденциальной информации */}
                <Route path="/privacy-fr" element={<PrivacyPageFr />} />
                 {/* Политика обработки конфиденциальной информации */}
                 <Route path="/privacy-in" element={<PrivacyPageIn />} />
                 {/* Политика обработки конфиденциальной информации */}
                 <Route path="/meter-readings" element={<MeterReadings />} />
                {/* Удаление профиля */}
                <Route path="/remove-profile" element={<RemoveProfile />} />
                {/* Not found page */}
                <Route path="/*" element={<NotFoundPage />} />
            </Routes>
        </main>
    );
}

export default App; 