import React from 'react';

function ButtonAppStore({ img, text, onClick, style }) {
    return (
        <button className="button-app-store" onClick={onClick}>
            <img src={img} alt={text} className={`button-app-store__img {style}`} />
            <p className="button-app-store__title">{text}</p>
        </button>
    )
}

export default ButtonAppStore;