function PrivacyPageFr() {

    return (
        <div className="privacy-page">
            <h1>Politique de confidentialité</h1>
            <p>Dernière mise à jour : 10 novembre 2023</p>
            <p>Cette Politique de confidentialité décrit nos politiques et procédures concernant la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et vous informe de vos droits en matière de confidentialité et de la manière dont la loi vous protège.</p>
            <p>Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le service, vous acceptez la collecte et l'utilisation des informations conformément à cette politique de confidentialité. Cette politique de confidentialité a été créée avec l'aide du <a href="https://www.privacypolicies.com/privacy-policy-generator/" target="_blank">générateur de politique de confidentialité</a>.</p>
            <h2>Interprétation et définitions</h2>
            <h3>Interprétation</h3>
            <p>Les mots dont la première lettre est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes auront la même signification qu'ils apparaissent au singulier ou au pluriel.</p>
            <h3>Définitions</h3>
            <p>Aux fins de cette politique de confidentialité :</p>
            <ul>
                <li>
                    <p><strong>Compte</strong> signifie un compte unique créé pour que vous puissiez accéder à notre service ou à certaines parties de notre service.</p>
                </li>
                <li>
                    <p><strong>Affilié</strong> signifie une entité qui contrôle, est contrôlée par ou est sous le contrôle commun d'une partie, où "contrôle" signifie la propriété de 50 % ou plus des actions, de l'intérêt en capital ou d'autres valeurs mobilières ayant droit de vote pour l'élection des directeurs ou d'autres autorités de gestion.</p>
                </li>
                <li>
                    <p><strong>Application</strong> fait référence à Auto-Diary, le programme logiciel fourni par la société.</p>
                </li>
                <li>
                    <p><strong>Société</strong> (désignée comme "la Société", "Nous", "Notre" dans cet accord) fait référence à Auto-Diary.</p>
                </li>
                <li>
                    <p><strong>Pays</strong> fait référence à : Russie</p>
                </li>
                <li>
                    <p><strong>Appareil</strong> signifie tout appareil pouvant accéder au service, tel qu'un ordinateur, un téléphone portable ou une tablette numérique.</p>
                </li>
                <li>
                    <p><strong>Données personnelles</strong> désigne toute information se rapportant à une personne identifiée ou identifiable.</p>
                </li>
                <li>
                    <p><strong>Service</strong> fait référence à l'Application.</p>
                </li>
                <li>
                    <p><strong>Prestataire de services</strong> désigne toute personne physique ou morale qui traite les données au nom de la société. Il s'agit de sociétés ou de personnes tierces employées par la société pour faciliter le service, fournir le service au nom de la société, effectuer des services liés au service ou aider la société à analyser la manière dont le service est utilisé.</p>
                </li>
                <li>
                    <p><strong>Données d'utilisation</strong> fait référence aux données collectées automatiquement, générées soit par l'utilisation du service, soit par l'infrastructure du service elle-même (par exemple, la durée d'une visite de page).</p>
                </li>
                <li>
                    <p><strong>Vous</strong> désigne la personne accédant ou utilisant le service, ou la société ou autre entité légale au nom de laquelle cette personne accède ou utilise le service, le cas échéant.</p>
                </li>
            </ul>
            <h2>Collecte et utilisation de vos données personnelles</h2>
            <h3>Types de données collectées</h3>
            <h4>Données personnelles</h4>
            <p>Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnelles identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations personnelles identifiables peuvent inclure, sans s'y limiter :</p>
            <ul>
                <li>
                    <p>Adresse e-mail</p>
                </li>
                <li>
                    <p>Données d'utilisation</p>
                </li>
            </ul>
            <h4>Données d'utilisation</h4>
            <p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
            <p>Les données d'utilisation peuvent inclure des informations telles que l'adresse IP de votre appareil (par exemple, adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, des identifiants uniques de l'appareil et d'autres données de diagnostic.</p>
            <p>Lorsque vous accédez au service par le biais d'un appareil mobile, nous pouvons collecter automatiquement certaines informations, notamment, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'ID unique de votre appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation mobile, le type de navigateur Internet mobile que vous utilisez, des identifiants uniques de l'appareil et d'autres données de diagnostic.</p>
            <p>Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre service ou lorsque vous accédez au service par le biais d'un appareil mobile.</p>
            <h4>Informations collectées lors de l'utilisation de l'application</h4>
            <p>Lors de l'utilisation de notre application, afin de fournir des fonctionnalités de notre application, nous pouvons collecter, avec votre autorisation préalable :</p>
            <ul>
                <li>
                    <p>Informations concernant votre emplacement</p>
                </li>
                <li>
                    <p>Photos et autres informations de l'appareil photo et de la bibliothèque photo de votre appareil</p>
                </li>
            </ul>
            <p>Nous utilisons ces informations pour fournir des fonctionnalités de notre service, pour améliorer et personnaliser notre service. Les informations peuvent être téléchargées sur les serveurs de la société et/ou sur les serveurs d'un prestataire de services, ou elles peuvent simplement être stockées sur votre appareil.</p>
            <p>Vous pouvez activer ou désactiver l'accès à ces informations à tout moment via les paramètres de votre appareil.</p>
            <h3>Utilisation de vos données personnelles</h3>
            <p>La société peut utiliser les données personnelles à des fins suivantes :</p>
            <ul>
                <li>
                    <p><strong>Pour fournir et maintenir notre service</strong>, y compris pour surveiller l'utilisation de notre service.</p>
                </li>
                <li>
                    <p><strong>Pour gérer votre compte :</strong> pour gérer votre inscription en tant qu'utilisateur du service. Les données personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du service qui sont disponibles pour vous en tant qu'utilisateur enregistré.</p>
                </li>
                <li>
                    <p><strong>Pour l'exécution d'un contrat :</strong> le développement, le respect et la réalisation du contrat d'achat des produits, articles ou services que vous avez achetés, ou de tout autre contrat avec nous par le biais du service.</p>
                </li>
                <li>
                    <p><strong>Pour vous contacter :</strong> Pour vous contacter par e-mail, appels téléphoniques, SMS ou d'autres formes équivalentes de communication électronique, telles que les notifications push d'une application mobile concernant les mises à jour ou les communications informatives liées aux fonctionnalités, produits ou services contractés, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.</p>
                </li>
                <li>
                    <p><strong>Pour vous fournir</strong> des actualités, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à ceux que vous avez déjà achetés ou sur lesquels vous vous êtes renseigné, sauf si vous avez choisi de ne pas recevoir de telles informations.</p>
                </li>
                <li>
                    <p><strong>Pour gérer vos demandes :</strong> Pour répondre et gérer vos demandes auprès de nous.</p>
                </li>
                <li>
                    <p><strong>Pour les transferts d'entreprise :</strong> Nous pouvons utiliser vos informations pour évaluer ou effectuer une fusion, une cession, une restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert de la totalité ou d'une partie de nos actifs, que ce soit en tant qu'entreprise en activité ou dans le cadre d'une procédure de faillite, de liquidation ou de procédure similaire, dans laquelle les données personnelles détenues par nous concernant nos utilisateurs de service font partie des actifs transférés.</p>
                </li>
                <li>
                    <p><strong>Pour d'autres fins</strong> : Nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse des données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes promotionnelles et l'évaluation et l'amélioration de notre service, de nos produits, services, marketing et de votre expérience.</p>
                </li>
            </ul>
            <p>Nous pouvons partager vos informations personnelles dans les situations suivantes :</p>
            <ul>
                <li><strong>Avec les prestataires de services :</strong> Nous pouvons partager vos informations personnelles avec des prestataires de services pour surveiller et analyser l'utilisation de notre service, et pour vous contacter.</li>
                <li><strong>Lors de transferts commerciaux :</strong> Nous pouvons partager ou transférer vos informations personnelles en lien avec, ou pendant des négociations de, toute fusion, vente d'actifs de la société, financement ou acquisition de tout ou partie de notre entreprise par une autre société.</li>
                <li><strong>Avec les affiliés :</strong> Nous pouvons partager vos informations avec nos affiliés, auquel cas nous exigerons que ces affiliés respectent cette politique de confidentialité. Les affiliés incluent notre société mère et toute autre filiale, partenaire de coentreprise ou autre société que nous contrôlons ou qui est sous notre contrôle commun.</li>
                <li><strong>Avec les partenaires commerciaux :</strong> Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.</li>
                <li><strong>Avec d'autres utilisateurs :</strong> lorsque vous partagez des informations personnelles ou interagissez autrement dans les espaces publics avec d'autres utilisateurs, ces informations peuvent être consultées par tous les utilisateurs et peuvent être diffusées publiquement.</li>
                <li><strong>Avec votre consentement :</strong> Nous pouvons divulguer vos informations personnelles à d'autres fins avec votre consentement.</li>
            </ul>
            <h3>Conservation de vos données personnelles</h3>
            <p>La société conservera vos données personnelles uniquement pendant la durée nécessaire aux fins énoncées dans cette politique de confidentialité. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour respecter nos obligations légales (par exemple, si nous sommes tenus de conserver vos données pour respecter les lois applicables), résoudre des litiges et faire respecter nos accords et politiques juridiques.</p>
            <p>La société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf lorsque ces données sont utilisées pour renforcer la sécurité ou améliorer la fonctionnalité de notre service, ou lorsque nous sommes légalement obligés de conserver ces données plus longtemps.</p>
            <h3>Transfert de vos données personnelles</h3>
            <p>Vos informations, y compris les données personnelles, sont traitées dans les bureaux d'exploitation de la société et dans tout autre lieu où les parties impliquées dans le traitement sont situées. Cela signifie que ces informations peuvent être transférées vers, et conservées sur, des ordinateurs situés en dehors de votre État, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre juridiction.</p>
            <p>Votre consentement à cette politique de confidentialité suivi de votre soumission de telles informations représente votre accord à ce transfert.</p>
            <p>La société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données soient traitées de manière sécurisée et conformément à cette politique de confidentialité, et aucun transfert de vos données personnelles n'aura lieu vers une organisation ou un pays à moins qu'il n'y ait des contrôles adéquats en place, y compris la sécurité de vos données et autres informations personnelles.</p>
            <h3>Suppression de vos données personnelles</h3>
            <p>Vous avez le droit de supprimer ou de demander que nous vous aidions à supprimer les données personnelles que nous avons collectées vous concernant.</p>
            <p>Notre service peut vous donner la possibilité de supprimer certaines informations vous concernant depuis le service lui-même.</p>
            <p>Vous pouvez mettre à jour, modifier ou supprimer vos informations à tout moment en vous connectant à votre compte, le cas échéant, et en visitant la section des paramètres de compte qui vous permet de gérer vos informations personnelles. Vous pouvez également nous contacter pour demander l'accès, la correction ou la suppression de toute information personnelle que vous nous avez fournie.</p>
            <p>Veuillez noter, cependant, que nous pouvons être amenés à conserver certaines informations lorsque nous avons une obligation légale ou une base légale pour le faire.</p>
            <h3>Divulgation de vos données personnelles</h3>
            <h4>Transactions commerciales</h4>
            <p>Si la société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous fournirons un avis avant que vos données personnelles ne soient transférées et ne deviennent soumises à une politique de confidentialité différente.</p>
            <h4>Application de la loi</h4>
            <p>Dans certaines circonstances, la société peut être tenue de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valides des autorités publiques (par exemple, un tribunal ou une agence gouvernementale).</p>
            <h4>Autres exigences légales</h4>
            <p>La société peut divulguer vos données personnelles de bonne foi si une telle action est nécessaire pour :</p>
            <ul>
                <li>Se conformer à une obligation légale</li>
                <li>Protéger et défendre les droits ou les biens de la société</li>
                <li>Prévenir ou enquêter sur des actes répréhensibles éventuels en lien avec le service</li>
                <li>Protéger la sécurité personnelle des utilisateurs du service ou du public</li>
                <li>Se protéger contre une responsabilité légale</li>
            </ul>
            <h3>Sécurité de vos données personnelles</h3>
            <p>La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet ou de stockage électronique n'est totalement sûre. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir une sécurité absolue.</p>
            <h2>Vie privée des enfants</h2>
            <p>Notre service ne s'adresse à personne de moins de 13 ans. Nous ne collectons délibérément aucune information identifiable personnellement auprès de toute personne de moins de 13 ans. Si vous êtes parent ou tuteur et que vous êtes conscient que votre enfant nous a fourni des données personnelles, veuillez nous contacter. Si nous découvrons que nous avons collecté des données personnelles auprès de toute personne de moins de 13 ans sans vérification du consentement parental, nous prendrons des mesures pour supprimer ces informations de nos serveurs.</p>
            <p>Si nous devons nous baser sur le consentement comme base légale pour le traitement de vos informations et que votre pays exige le consentement d'un parent, nous pouvons exiger le consentement de vos parents avant de collecter et d'utiliser ces informations.</p>
            <h2>Liens vers d'autres sites web</h2>
            <p>Notre service peut contenir des liens vers d'autres sites web qui ne sont pas exploités par nous. Si vous cliquez sur un lien d'un tiers, vous serez redirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la politique de confidentialité de chaque site que vous visitez.</p>
            <p>Nous n'avons aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques de tout site web tiers ou service.</p>
            <h2>Changements à cette politique de confidentialité</h2>
            <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.</p>
            <p>Nous vous informerons également par e-mail et/ou par un avis bien visible sur notre service, avant que le changement ne devienne effectif, et nous mettrons à jour la date "Dernière mise à jour" en haut de cette politique de confidentialité.</p>
            <p>Il est recommandé de consulter périodiquement cette politique de confidentialité pour tout changement. Les changements à cette politique de confidentialité sont effectifs lorsqu'ils sont publiés sur cette page.</p>
            <h2>Contactez-nous</h2>
            <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter :</p>
            <ul>
                <li>Par e-mail : <a href="/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="066f68606946677372692b62686370686f6d287473">[email&nbsp;protected]</a></li>
            </ul>

        </div>
    )
}

export default PrivacyPageFr;